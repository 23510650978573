import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { css } from "@emotion/core"

import { switchPage } from "src/utils/navigateTo"
import autoPolicyUpdatesDesktopPNG from "src/images/home/auto_policy_updates_desktop.png"
import autoPolicyUpdatesDesktopWEBP from "src/images/home/auto_policy_updates_desktop.webp"
import autoPolicyUpdatesMobilePNG from "src/images/home/auto_policy_updates_mobile.png"
import autoPolicyUpdatesMobileWEBP from "src/images/home/auto_policy_updates_mobile.webp"
import { Col, ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"
import { ButtonTransparent, BookDemoButton } from "src/componentsV2/ui/Button"

import { HeroBannerContainer } from "./style"

const HeroContainer = styled(ContainerPage)`
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    padding-top: 20px;
    margin-bottom: 0px;
  }
`

const ColStyled = styled(Col)`
  ${tw`text-center`}
  width: 100%;
  @media (min-width: 768px) {
    min-width: auto;
  }
`

const RowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const HeroBanner = () => {
  return (
    <HeroContainer data-loadable-component="hero-banner__landing">
      <WrapperPage>
        <HeroBannerContainer>
          <RowStyled>
            <ColStyled className="md:max-w-lg">
              <Title className="mb-5 mt-6 px-8 md:px-0">
                World's Best Employee Handbook Builder
              </Title>
              <SubTitle>
                <p
                  css={css`
                  text-align: left;
                    @media (min-width: 768px) {
                        width: 490px;
                    }
                  `}
                >
                  Create and manage your employee handbooks with AirMason.
                  AirMason’s employee handbook builder simplifies the process
                  for HR.
                </p>
                <ul
                  className="mb-5"
                  css={css`
                    font-size: 16px;
                    padding-left: 15px;
                    text-align: left;
                      @media (min-width: 768px) {
                          width: 490px;
                      }
                  `}
                >
                  <li>
                    Stay compliant with automated policy updates across all 50
                    states
                  </li>
                  <li>
                    HR Legal, Professional Copywriting and Custom Design support
                    for all your employee handbooks and policies
                  </li>
                  <li>
                    Ready-made employee handbook templates and policy addendums
                    to get you get started today
                  </li>
                </ul>
              </SubTitle>
              <div className="hidden items-center md:flex">
                <BookDemoButton
                  onClick={() => switchPage.bookDemo()}
                  style={{ height: 50, width: 210, borderRadius: 30 }}
                />
                <div className="mx-6">Or</div>
                <ButtonTransparent
                  onClick={switchPage.plans}
                  css={css`
                    width: 210px;
                  `}
                >
                  Try it for free
                </ButtonTransparent>
              </div>
            </ColStyled>
            <ColStyled
              css={css`
                margin-top: 20px; /* Default for mobile */

                @media (min-width: 768px) {
                  margin-top: 150px; /* Applied for screens wider than 768px (desktop) */
                }
                @media (min-width: 1440px) {
                  img {
                    width: 490px;  
                    margin-left: 130px;
                  }
                }
              `}
            >
              <picture>
                <source
                  srcSet={autoPolicyUpdatesDesktopWEBP}
                  type="image/webp"
                  media="(min-width: 426px)"
                />
                <source
                  srcSet={autoPolicyUpdatesDesktopPNG}
                  type="image/png"
                  media="(min-width: 426px)"
                />
                <source
                  srcSet={autoPolicyUpdatesMobileWEBP}
                  type="image/webp"
                />
                <source srcSet={autoPolicyUpdatesMobilePNG} type="image/png" />
                <img
                  src={autoPolicyUpdatesDesktopWEBP}
                  alt="hero-banner"
                  width="420"
                  height="510"
                />
              </picture>
            </ColStyled>
          </RowStyled>
        </HeroBannerContainer>
      </WrapperPage>
    </HeroContainer>
  )
}

export default HeroBanner
